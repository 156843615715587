import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  private consentKey = 'cookie-consent';
  private scriptLoaded = new BehaviorSubject<boolean>(false);
  public scriptLoaded$ = this.scriptLoaded.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  getConsent(): string | null {
    if (!isPlatformBrowser(this.platformId)) return null
    return localStorage.getItem(this.consentKey);
  }

  setConsent(consent: string): void {
    if (!isPlatformBrowser(this.platformId)) return
    localStorage.setItem(this.consentKey, consent);
    if (consent === 'all') {
      this.loadAdsenseScript();
    }
  }

   loadAdsenseScript(): void {
    if (!isPlatformBrowser(this.platformId)) return
    if (this.getConsent() !== 'all' || this.scriptLoaded.getValue()) {
      return;
    }
    console.log("adsense")
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5368296956805437';
    document.head.appendChild(script);

    script.onload = () => {
      (window as any).adsbygoogle = (window as any).adsbygoogle || [];
      if ((window as any).adsbygoogle.length === 0) {
        (window as any).adsbygoogle.push({});
      }

      this.scriptLoaded.next(true);
    };
  }
}