import { Component, OnInit } from '@angular/core';
import { CookieConsentService } from '../shared/services/cookie-consent.service';
import { FirebaseAnalyticsService } from "../shared/services/firebase-analytics.service";
import { trigger, transition, style, animate } from '@angular/animations';

import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  animations: [
    trigger('modalTransition', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0)' }),
        animate('300ms 200ms', style({ opacity: 1, transform: 'scaleY(1)' }))
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'scaleY(0)' }))
      ])
    ])
  ]
})
export class CookieConsentComponent implements OnInit {
  showBanner: boolean | null = null;
  showModal: boolean = false;
  analyticCheckbox: boolean = false;

  constructor(
    private cookieConsentService: CookieConsentService,
    private firebaseAnalyticService: FirebaseAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkConsentStatus();
    }
  }

  private checkConsentStatus(): void {
    const consent = this.cookieConsentService.getConsent();
    if (!consent) {
      this.showBanner = true;
    } else {
      this.loadTrackingScripts(consent);
    }
  }

  acceptAllCookies(): void {
    this.setConsentAndLoadScripts('all');
  }

  saveCookies(): void {
    const consent = this.analyticCheckbox ? 'all' : 'necessary';
    this.setConsentAndLoadScripts(consent);
  }

  declineCookies(): void {
    this.cookieConsentService.setConsent('declined');
    this.showBanner = false;
    this.showModal = false;
  }

  showDetail(): void {
    this.showModal = true;
  }

  hideDetail(): void {
    this.showModal = false;
  }

  checkAnalyticCheckbox(event: any): void {
    this.analyticCheckbox = event.target.checked;
  }

  private setConsentAndLoadScripts(consent: string): void {
    this.cookieConsentService.setConsent(consent);
    this.showBanner = false;
    this.showModal = false;
    this.loadTrackingScripts(consent);
  }

  private loadTrackingScripts(consent: string): void {
    if (consent === 'all') {
      this.cookieConsentService.loadAdsenseScript();
      this.firebaseAnalyticService.loadFirebase();
    } else if (consent === 'necessary') {
      this.cookieConsentService.loadAdsenseScript();
    }
  }
}