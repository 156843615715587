<header id="header" class="header-layout1">
  <div id="sticky-header" class="menu-area transparent-header" [class.sticky-menu]="sticky">
    <div class="container custom-container">
      <div class="row">
        <div class="col-12">
          <div class="menu-wrap">
            <nav class="menu-nav justify-content-between">
              <div class="nav-logo">
                <a routerLink="/home">
                  <img src="/assets/img/logo/logo.svg" style="height: 35px;" alt="Logo"> CloudTools
                </a>
              </div>
              <div class="navbar-wrap main-menu d-none d-lg-flex">
                <ul class="navigation">
                  <li [routerLinkActive]="['active']">
                    <a class="section-link pointer" routerLink="/home">Home</a>
                  </li>
                  <li class="menu-item-has-children" [class.active]="isToolsRouteActive()">
                    <a [routerLink]="['/home']" [fragment]="'tools'" class="section-link pointer">Tools</a>
                    <ul class="sub-menu">
                      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/tools/qr-generator">QR Code Generator</a>
                      </li>
                      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/tools/image-converter">Image Converter</a>
                      </li>
                      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/tools/my-ip">My IP Address</a>
                      </li>
                    </ul>
                  </li>
                  <li [class.active]="isFAQRouteActive()">
                    <a class="section-link pointer" [routerLink]="['/home']" [fragment]="'faq'">FAQ</a>
                  </li>
                  <li [routerLinkActive]="['active']">
                    <a routerLink="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus"
                   class="mobile-nav-toggler d-flex d-lg-none">
                <i class="fas fa-bars"></i>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile offcanvas menu -->
  <app-mobile-offcanvas [menuTwo]="true"/>
  <!-- mobile offcanvas menu -->
</header>
