<div class="mobile-menu">
  <nav class="menu-box">
    <div
      class="close-btn"
      (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus"
    >
      <i class="fas fa-times"></i>
    </div>
    <div class="nav-logo">
      <a routerLink="/home">
        <img src="/assets/img/logo/logo.svg" style="height: 35px;" alt="Logo"> CloudTools
      </a>
    </div>
    <div class="menu-outer">
      <ul class="navigation">
        <li>
          <a class="section-link pointer" routerLink="/home">Home</a>
        </li>
        <li class="menu-item-has-children">
          <a class="section-link pointer" [routerLink]="['/tools']">Tools</a>
          <ul class="sub-menu" [ngStyle]="{ display: openSubMenu === 'tools' ? 'block' : 'none' }">
            <li>
              <a routerLink="/tools/qr-generator">QR Code Generator</a>
            </li>
            <li>
              <a routerLink="/tools/image-converter">Image Converter</a>
            </li>
            <li>
              <a routerLink="/tools/my-ip">My IP Address</a>
            </li>
          </ul>
          <div (click)="handleSubMenu('tools')" class="dropdown-btn" [class.open]="openSubMenu === 'tools'">
            <span class="fas fa-angle-down"></span>
          </div>
        </li>
        <li>
          
          <a class="section-link pointer" [routerLink]="['/faq']">FAQ</a>
        </li>
        <li>
          <a routerLink="/contact">Contact</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div
  class="menu-backdrop"
  (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus"
></div>
